
  import { Component, Watch } from 'vue-property-decorator'
  import BaseForm from '@/components/forms/view/BaseForm.vue'
  import LinkedAuto from '@/components/forms/fields/LinkedAuto.vue'
  import { plainToInstance } from 'class-transformer'
  import { Form } from '@/entities/public/Resource/metadata'
  import { Lead } from '@/entities/crm'
  import InitialFields from '@/components/forms/fields/InitialFields.vue'
  import LinkedPerson from '@/components/forms/fields/LinkedPerson.vue'
  import FieldTitle from '@/components/forms/fields/FieldTitle.vue'
  import StockPrices from '@/components/toolkit/details/row/custom/StockPrices.vue'
  import AutoHeader from '@/components/toolkit/details/row/expandable/auto/header.vue'
  import Simple from '@/components/toolkit/details/row/simple.vue'
  import { Negotiation, ProcessTrafficTicket } from '@/entities/purchase'
  import GFiles from '@/components/core/files/GFiles.vue'
  import { Process } from '@/entities/settings'
  import { isValidNumber, parseToNumber } from '@/utils/general'
  import { NegotiationView } from '@/components/forms/view/NegotiationView'
  import { Debounce } from '@/utils/decorators'
  import TrafficTicketComponent from '@/components/forms/trafficTicket/TrafficTicketComponent.vue'

@Component({
  components: {
    TrafficTicketComponent,
    GFiles,
    Simple,
    StockPrices,
    FieldTitle,
    LinkedPerson,
    InitialFields,
    LinkedAuto,
    BaseForm,
    AutoHeader,
  },
})
  export default class NegotiationExecutiveForm extends NegotiationView {
  negotiation = plainToInstance(Negotiation, {})
  lead = plainToInstance(Lead, {})
  title = ''
  process: Process = plainToInstance(Process, {})
  idProcess = null
  idProcessTrafficTicket = null
  expectedPriceMessage = ''
  penaltiesErrorMessage = ''
  appealLimit = null
  showDetail = false
  openTrafficTicket = false
  isPriceExpectedLower = false
  processTrafficTicketSelected = null
  declare $refs: {
    form: HTMLFormElement
  };

  penalties: ProcessTrafficTicket[] = []

  metadata = {}
  formData = {
    expectedPrice: null,
    cav: [],
    penalties: [],
    legalReport: [],
    linkLegalReport: null,
  }

  processTrafficStatus = {
    toConfirm: null,
    unpaid: null,
  }

  fields = {
    expectedPrice: {
      properties: {},
      rules: this.fieldRequired,
    },
    cav: {
      properties: {
        label: '',
        accept: '',
        fileTypeId: null,
        multiple: false,
        name: '',
        appendOuterIcon: '',
      },
    },
    penalties: {
      properties: {
        label: '',
        accept: '',
        fileTypeId: null,
        name: '',
        multiple: false,
        appendOuterIcon: '',
      },
    },
    legalReport: {
      properties: {
        label: '',
        accept: '',
        fileTypeId: null,
        name: '',
        multiple: false,
        appendOuterIcon: '',
      },
    },
    paymentProof: {
      properties: {
        label: '',
        accept: '',
        fileTypeId: null,
        name: '',
        multiple: false,
        appendOuterIcon: '',
      },
    },
    linkLegalReport: {
      properties: {},
    },
  }

  async mounted () {
    await this.setMetadata()

    const { uid, title, metadata } = this

    if (uid) {
      await this.getNegotiationInfo(uid)
    }

    if (!this.isBreadCrumbPresent(title)) {
      this.setFormCrumbs(metadata, title, Boolean(this.negotiation?.id))
    }
  }

  async getNegotiationInfo (id) {
    const negotiation = await this.fetchData({
      query: { name: 'fetch', model: 'Negotiation', params: { id } },
      force: true,
    })
    this.negotiation = negotiation
    if (negotiation.status?.isAppealed) {
      this.formData.expectedPrice = this.negotiation?.negotiationResponseAppealed?.value
    }

    const fileInfo = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { process: { table_name: { _eq: 'negotiation' } } },
    })

    this.setProperties(fileInfo, 'penalty_certificate', 'penalties')
    this.setProperties(fileInfo, 'cav', 'cav')
    this.setProperties(fileInfo, 'legal_report', 'legalReport')
    await this.setTrafficTicketProperties()
    await this.setFilesData(negotiation)
    this.setDetails()
  }

  async setTrafficTicketProperties () {
    const trafficTicket = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { process: { table_name: { _eq: 'traffic_ticket' } } },
    })

    this.setProperties(trafficTicket, 'payment_proof', 'paymentProof')

    const process = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { process: { table_name: { _eq: 'process_traffic_ticket' } } },
    })

    this.processTrafficStatus.toConfirm = process.find(p => p.isToConfirm)?.id
    this.processTrafficStatus.unpaid = process.find(p => p.isUnpaid)?.id
  }

  async setFilesData (negotiation) {
    const { idProcess } = this
    const processAppraisal = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'appraisal' } },
    })

    const { cav, report, penalties } = await this.findAssociatedFiles(idProcess, negotiation.id)

    this.formData.cav = cav?.length ? [cav[0]] : []
    this.formData.legalReport = report?.length ? [report[0]] : []
    if (report?.[0]?.file?.sourceLink) {
      this.formData.linkLegalReport = report?.[0]?.file?.sourceLink
    }
    this.formData.penalties = penalties?.length ? [penalties[0]] : []
    this.penalties = await this.getPenalties(this.formData?.penalties?.[0]?.id)

    const {
      cav: cavAppraisal,
      report: reportAppraisal,
    } = await this.findAssociatedFiles(processAppraisal[0].id, negotiation?.inspection?.appraisal?.id, false)

    if (!cav?.length) {
      this.formData.cav = cavAppraisal?.length ? [cavAppraisal[0]] : []
    }

    if (!report?.length) {
      this.formData.legalReport = reportAppraisal?.length ? [reportAppraisal[0]] : []
      if (reportAppraisal?.[0]?.file?.sourceLink) {
        this.formData.linkLegalReport = reportAppraisal?.[0]?.file?.sourceLink
      }
    }
  }

  cancelNegotiation () {
    const { formData } = this
    this.setBackup(null)
    this.close([...formData.penalties, ...formData.cav, ...formData.legalReport])
  }

  async findAssociatedFiles (idProcess, id, isNegotiation = true) {
    if (!id) return {}
    const cav = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: { _and: [{ id_process_record: { _eq: id } }, { parameter: { process: { id: { _eq: idProcess } } } }, { parameter: { name: { _eq: 'cav' } } }] },
      force: true,
    })

    if (cav.length && isNegotiation) {
      this.negotiation.cavValidation = {
        expirationDate: cav[0].file.expirationDate,
        validations: cav[0].validation,
      }
    }

    const report = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: { _and: [{ id_process_record: { _eq: id } }, { parameter: { process: { id: { _eq: idProcess } } } }, { parameter: { file_type: { name: { _eq: 'legal_report' } } } }] },
      force: true,
    })

    const penalties = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: { _and: [{ id_process_record: { _eq: id } }, { parameter: { process: { id: { _eq: idProcess } } } }, { parameter: { file_type: { name: { _eq: 'penalty_certificate' } } } }] },
      force: true,
    })

    return {
      cav,
      report,
      penalties,
    }
  }

  saveBackup () {
    const { backup, formData } = this

    if (backup) {
      backup.negotiationForm = { ...formData }
      this.setBackup(backup)
    } else {
      this.setBackup({ negotiationForm: { ...formData } })
    }
  }

  async setMetadata () {
    const { metadata } = this.getForm('Negotiation', 'negotiation')
    const { fields, form } = metadata as Form
    this.metadata = metadata
    this.title = form.title
    this.fields.expectedPrice.properties = fields.expectedPrice?.properties
    Object.assign(this.fields.cav.properties, fields.cav?.properties)
    Object.assign(this.fields.legalReport.properties, fields.legalReport?.properties)
    Object.assign(this.fields.penalties.properties, fields.penalties?.properties)
    this.fields.linkLegalReport.properties = fields.linkLegalReport?.properties
    await this.setFilesFieldsData()
  }

  async setFilesFieldsData () {
    const fileInfo = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { process: { table_name: { _eq: 'negotiation' } } },
    })

    this.setProperties(fileInfo, 'penalty_certificate', 'penalties')
    this.setProperties(fileInfo, 'cav', 'cav')
    this.setProperties(fileInfo, 'legal_report', 'legalReport')

    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'negotiation' } },
    })

    const trafficProcess = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'traffic_ticket' } },
    })
    this.idProcessTrafficTicket = trafficProcess[0].id

    const { config, id } = process[0] as Record<string, any>

    this.appealLimit = config?.maximumAppeals
    this.idProcess = id
  }

  setDetails () {
    const { metadata, negotiation } = this
    this.metadata = {
      data: negotiation,
      metadata,
    }

    this.showDetail = Boolean(negotiation?.id)
  }

  validation () {
    const { formData, isPriceExpectedLower, expectedPriceMessage, isReadingFile } = this

    if (expectedPriceMessage?.length) {
      return true
    }

    if (!isPriceExpectedLower && !isReadingFile) return false
    const fields = ['cav', 'penalties', 'legalReport']

    for (const field of fields) {
      if (formData[field].length === 0) {
        this.$refs[field].errorMessage = 'Debe subir un archivo'
        return true
      } else {
        if (this.$refs[field].errorMessage === 'Debe subir un archivo') {
          this.$refs[field].errorMessage = ''
        }
      }

      if (this.$refs[field]?.errorMessage?.length) {
        return true
      }
    }
  }

  filesWatcher () {
    const { formData: { cav, penalties, legalReport } } = this

    return {
      cav,
      penalties,
      legalReport,
    }
  }

  validationTrafficTickets () {
    const { negotiation, penalties } = this

    return negotiation?.status?.isToUpdating && penalties?.some(penalty => penalty.isPending)
  }

  async send () {
    if (this.validationTrafficTickets()) {
      this.penaltiesErrorMessage = 'Tiene multas pendientes'
      return
    }

    if (!this.$refs.form.validate() || this.validation()) {
      return
    }

    this.loadingForm = true
    const { isPriceExpectedLower, isReadingFile, formData, idEmployee, negotiation } = this

    if (negotiation?.status?.isToUpdating) {
      await this.changeStatusLegalReview(negotiation)
      await this.insertUpdateFile(this)
    } else {
      if (!isPriceExpectedLower && !isReadingFile) {
        await this.insertNegotiationAppealed(formData, negotiation, idEmployee)
      } else if (isPriceExpectedLower || isReadingFile) {
        await this.insertAgreedAmount(this)
        await this.insertUpdateFile(this)
      }
    }

    await this.close()
  }

  get maximumAppeals () {
    const { negotiation } = this
    return negotiation?.responses?.filter(response => response?.isAppealed)?.length || 0
  }

  validateExpectedPrice (val) {
    const { negotiation: { negotiationResponse }, appealLimit, maximumAppeals } = this
    if (val === null) return null
    const isPriceExpectedLower = isValidNumber(val) && isValidNumber(negotiationResponse?.value) && parseToNumber(val) <= parseToNumber(negotiationResponse?.value)
    this.isPriceExpectedLower = isPriceExpectedLower

    if (!isPriceExpectedLower) {
      if (maximumAppeals >= appealLimit) {
        this.expectedPriceMessage = 'Se ha alcanzado el máximo de apelaciones'
      }
    } else {
      this.expectedPriceMessage = ''
    }
  }

  get isReadingFile () {
    const { negotiation } = this

    return Boolean(negotiation?.status?.isReadingFile) || Boolean(negotiation?.status?.isLegalReview)
  }

  get isToUpdating () {
    const { negotiation } = this

    return Boolean(negotiation?.status?.isToUpdating)
  }

  get isApproved () {
    const { negotiation } = this
    return Boolean(negotiation?.status?.isApproved)
  }

  get change () {
    const { formData, isPriceExpectedLower } = this

    return JSON.stringify(formData) + JSON.stringify(isPriceExpectedLower)
  }

  @Watch('filesWatcher', { deep: true, immediate: true })
  onFilesChange (val) {
    const fields = ['cav', 'penalties', 'legalReport']

    for (const field of fields) {
      if (val?.[field]?.length > 0 && this.$refs[field].errorMessage === 'Debe subir un archivo') {
        this.$refs[field].errorMessage = ''
      }
    }
  }

  @Watch('formData.legalReport', { immediate: true, deep: true })
  onLegaReportChange (val) {
    if (val.length && val[0]?.file?.sourceLink) {
      this.formData.linkLegalReport = val[0].file.sourceLink
    }
  }

  get disabledSourceLink () {
    const { formData: { legalReport } } = this

    if (legalReport.length) {
      return Boolean(legalReport[0]?.id && legalReport[0]?.file?.sourceLink)
    }
    return null
  }

  get disableFields () {
    const { negotiation } = this

    return Boolean(negotiation?.closingReason)
  }

  @Watch('formData', { immediate: true, deep: true })
  @Debounce(200)
  onFormDataChange (val) {
    const fields = ['cav', 'penalties', 'legalReport']
    const { ids, fieldsWithIds } = this.processFields(fields, val)
    this.checkForDuplicates(ids, fieldsWithIds)
  }

  processFields (fields, val) {
    const ids = {}
    const fieldsWithIds = {}

    for (const field of fields) {
      if (this.$refs[field] && this.$refs[field].errorMessage === 'El archivo está duplicado') {
        this.$refs[field].errorMessage = ''
      }
      const id = this.isArrayFiles(val[field]) ? val[field][0]?.id : val[field]?.[0]?.file?.id
      if (id) {
        const parsedId = parseToNumber(id)
        ids[parsedId] = (ids[parsedId] || 0) + 1
        if (!fieldsWithIds[parsedId]) {
          fieldsWithIds[parsedId] = []
        }
        fieldsWithIds[parsedId].push(field)
      }
    }

    return { ids, fieldsWithIds }
  }

  get alertMessage () {
    const { maximumAppeals, appealLimit } = this

    if (maximumAppeals === appealLimit - 1) {
      return maximumAppeals === 0
        ? 'Este es tu único intento para apelar. Por favor, procede con cuidado.'
        : 'Este es tu último intento para apelar. Por favor, procede con cuidado.'
    }

    return null
  }

  loadingFile (flag) {
    this.isUploadingFile = flag
  }

  checkForDuplicates (ids, fieldsWithIds) {
    for (const id in ids) {
      if (ids[id] > 1) {
        for (const field of fieldsWithIds[id]) {
          this.$refs[field].errorMessage = 'El archivo está duplicado'
        }
      }
    }
  }

  setProperties (fileInfo, fileTypeName, fieldKey) {
    if (!fileInfo?.length) return
    const info = fileInfo.find(fileParameter => fileParameter.name === fileTypeName)
    if (info) {
      this.fields[fieldKey].properties.accept = info.fileType.mimes
      this.fields[fieldKey].properties.multiple = info.multiple
      this.fields[fieldKey].properties.fileTypeId = info.fileType.id
      this.fields[fieldKey].properties.name = info.name
      this.fields[fieldKey].properties.label = info.description
    }
  }

  editPenalty (item: ProcessTrafficTicket) {
    this.penaltiesErrorMessage = ''
    this.processTrafficTicketSelected = item
    this.openTrafficTicket = true
  }

  @Watch('openTrafficTicket', { immediate: true })
  async onOpenTrafficTicketChange (val) {
    if (!val) {
      if (this.formData?.penalties?.length) {
        this.penalties = await this.getPenalties(this.formData?.penalties?.[0]?.id)
      }
      this.processTrafficTicketSelected = null
    }
  }

  get isLowerPrice () {
    const { formData, negotiation } = this
    if (!isValidNumber(formData.expectedPrice)) return true

    return formData.expectedPrice > negotiation.negotiationResponse.value
  }
  }
